





























































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";

@Component({
  components: {}
})
export default class Home extends Vue {
  private langTitle: string = "";
  created() {
    let self: any = this;

    let hash = window.location.hash;
    let strArr = hash.split("?lang=");
    if(strArr.length >1) {
      this.getLangFromUrl();
    } else {
      this.langTitle = self.config.langs[self.config.currentLang].title;
      this.$i18n.locale = self.config.langs[self.config.currentLang].name;
      this.$store.state.type = self.config.langs[self.config.currentLang].name;
    }
    
  }
  beforeUpdate() {
    this.getLangFromUrl();
  }
  private getLangFromUrl() {
    let _this = this;
    let hash = window.location.hash;
    let strArr = hash.split("?lang=");
    if(strArr.length  > 1) {
      let e = strArr[1];
      if(['en', 'zh'].indexOf(e) > -1) {

      } else {
        e = 'en';
        
      }
      this.handleChangeLang(e);
    }
  }
  private goDown() {
    let self: any = this;
    // window.location.href = self.config.appUrl;
    // console.log(document.querySelector('#screenshot'))
    document
      .querySelector("#screenshot")
      .scrollIntoView({ behavior: "smooth" });
  }
  private handleCommand(e: any) {
    // let self: any = this;
    // this.$i18n.locale = e;
    // this.$store.state.type = e
    // localStorage.setItem("applang", e);
    // self.config.langs.forEach((element, index) => {
    //   if (element.name == e) {
    //     localStorage.setItem("currentLang", index);
    //     this.langTitle = element.title;
    //   }
    // });
     let href = window.location.href;
      let strArr = href.split("?lang=");
      window.location.href = strArr[0] + '?lang=' + e;
  }
  private handleChangeLang(e:any) {
    let self: any = this;
    this.$i18n.locale = e;
    this.$store.state.type = e
    localStorage.setItem("applang", e);
    for (let i = 0; i < process.env.langs.length; i++) {
      const element = process.env.langs[i];
      if (element.name == e) {
        localStorage.setItem("currentLang", i.toString());
        self.langTitle = element.title;
        break;
      }
    }
  }
  private navigatorToLink(e) {
    let u = navigator.userAgent;
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    let url = "";
    let _this = this;
    if(e === 'ios') {
      axios.get("/client/api/findConfigByKey?configKey=website_node_ios").then(res => {
        url = res.data.data.configValue || '';
        window.location.href = url;
      });
    } else if(e === 'android') {
      axios.get("/client/api/findConfigByKey?configKey=website_node_apk").then(res => {
        url = res.data.data.configValue || '';
        window.location.href = url;
      });
    } else {
      axios.get("/client/api/findConfigByKey?configKey=ssl_node").then(res => {
        if(res.data.status == 1) {
          let ssl = res.data.data.configValue || '';
          if(isIOS) {
            alert(ssl);
            return;
          }
          _this.$copyText(ssl).then( e => {
              alert(_this.$t("ssl_copy"))
          }, function (e) {
              alert(ssl);
          })
        } else {
          alert(_this.$t("ssl_copy_fail"))
        }
      });
    }
  }
}
